import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { DataImage, Heading, Paragraph } from "../../../Styles/style";
import AboutBg from '../../../../assets/images/AboutBg.png';
import CustomeButton from "../../CustomButton";
import SendIcon from '@mui/icons-material/Send';
import AboutGrok from '../../../../assets/images/aboutgrok.png';
import AboutImage from '../../../../assets/images/aboutss.png';

const MainDiv = styled.div`
    background-image: url(${AboutBg});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    min-height: 100vh;
    margin: auto;
    display: grid;
    place-items: center;
`
const AboutGrid = styled(Box)`
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: white;
    box-shadow: 0px 0px 20px #56EC7F;
    padding: 30px 30px 20px;
    margin: 20px auto;
    min-height: 180px;
`
const TextINPUT = styled.textarea`
  background: #15151525;
  filter: brightness(5);
  padding: 10px 20px 0px;
  width: 100%;
  margin: 10px 0px;
  border-radius: 20px;
  border: none !important;
  color: black;
  :focus {
    outline: none !important;
    outline: none !important;
  }
`;
const ButtonGrid = styled(Grid)`
    padding: 20px 20px;
    @media (max-width: 600px){
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }
`
const About = () => {
    return (
        <MainDiv id='about'>
            <Container maxWidth='lg'>
                <Grid container>
                    <Grid item xs={12} sx={{ padding: '20px 30px' }}>
                        <Heading
                            fs599='80px'
                            data-aos="fade-up"
                            variant="h1">
                            About
                        </Heading>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <DataImage mw='100%' src={AboutImage}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paragraph
                            data-aos="fade-up"
                            fs='20px'
                            p='10px 30px'
                        >
                            Cache is Grok's second dog jumping into the ethereum blockchain, 
                            with a bite thats bigger than his bark and a tail that wags faster 
                            than the txs of an ETH sniper bot! Cache brings 
                            a whole new level of cuteness to the blockchain and always
                            remains loyal to his hodlers!
                        </Paragraph>
                    </Grid>
                    <ButtonGrid item xs={12} md={8}>
                        <CustomeButton
                            button='Buy Now'
                        />
                        <CustomeButton
                            button='View Chart'
                        />
                    </ButtonGrid>
                </Grid>
            </Container>
        </MainDiv>
    )
}
export default About;