import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Logo from '../../../../assets/images/heroImage.png';
import { MenuLink, MobileMainMenu, DrawerBox, DrawerDivider, Humburgger, HumburggerClose, MenuButton } from "../styles";
import { DataImage } from "../../../Styles/style";


const MobileMenu = (props) => {
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log('thest: ', anchor + " : " + open)
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) { return; }
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink href="/" p="5px 0px 0px">
        <DataImage mw='50px' src={Logo} />
      </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        {state["left"] ? (
          <HumburggerClose>Close</HumburggerClose>
        ) : (
          <Humburgger></Humburgger>
        )}
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
              <DataImage mw='70px' src={Logo} />
            </MenuLink>
            <Divider style={{ background: "white" }} />
            <List>
              {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass + " d-block"}>{value.title}</MenuLink>)}
            </List>
          </DrawerDivider>
          <DrawerDivider>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
}

export default MobileMenu;

