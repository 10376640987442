import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import SocialBg from '../../assets/images/socialbg.png';
import SocialHover from '../../assets/images/socialhover.png';

const PageWrapper = styled.div`
    background-color: ${ (props) => props.theme.pageColor};
    min-height: 100vh;
`
const DataImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props=>props.mw? props.mw : ""};
    padding: ${props=>props.pd? props.pd : ""};
    margin: ${props=>props.m? props.m : ""};
    @media (max-width: 599px){
        max-width: ${props=>props.mw599? props.mw599 : ""};;
    }
`
const SocialBox = styled(Box)`
    background-image: url(${SocialBg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 11px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
    transition: transform 0.2s ease;
    :hover {
        background-image: url(${SocialHover});
        transform: translateY(1px);
    }
`
const Paragraph = styled(Typography)`
    font-size: ${props=>props.fs? props.fs : '20px'};
    color: ${props=>props.cl? props.cl : 'white'};
    font-family: latinR;
    background-color: ${props=>props.bg? props.bg : ''};
    max-width: ${props=>props.mw? props.mw : ''};
    text-align: ${props=>props.ta? props.ta : ''};
    border: ${props=>props.bd? props.bd : ''};
    @media (max-width: 599px){
        font-size: ${props=>props.fs599? props.fs599 : '13px'};
        text-align: center;
    }
`
const Heading = styled(Typography)`
    font-size: ${props=>props.fs? props.fs : ''};
    color: ${props=>props.cl? props.cl : 'white'};
    font-family: latinR;
    background-color: ${props=>props.bg? props.bg : ''};
    max-width: ${props=>props.mw? props.mw : ''};
    text-align: ${props=>props.ta? props.ta : ''};
    @media (max-width: 599px){
        font-size: ${props=>props.fs599? props.fs599 : ''};
        text-align: center;
    }
`
export { PageWrapper, DataImage, Paragraph, Heading, SocialBox }