import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import React from "react";
import NavBg from '../../../../assets/images/navbg.png';
import LockerMenu from "../../LockerMenu";
import BackGround1 from '../../../../assets/images/bg1.png'
import BackGround2 from '../../../../assets/images/bg3.png'
import HeaderImage from '../../../../assets/images/headerimg.png';
import { DataImage, Paragraph } from "../../../Styles/style";
import ContractBg from '../../../../assets/images/contractbg.png';
import ContractBgHover from '../../../../assets/images/contractbg1.png';
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

const NavBackground = styled.div`
    background-image: url(${NavBg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
`
const MainDiv = styled.div`
  background: 
   url(${BackGround2}),  linear-gradient(to top, rgba(85, 236, 126, 0.5) , rgba(0, 0, 0, 0.5) 40%), 
    url(${BackGround1});
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* padding: 0px 0px 50px; */
  min-height: 100vh;
    @media (max-width: 599px){
        background-size: cover;
    }
`;

const ContainerGrid = styled(Container)`
    display: grid;
    place-items: center;
    padding: 60px 0px;
    min-height: calc(100vh - 70px); /* Subtract the height of NavBackground */
    /* align-items: flex-start;  */
`
const BoxBackground = styled(Box)`
    background-image: url(${ContractBg}); 
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;
    transition: transform 0.2s ease; /* Add a transition to the transform property */
    
    :hover {
        transform: translateY(5px); /* Adjust the translateY value for bending effect */
        background-image: url(${ContractBgHover}); 
    }
`;


const Header = () => {
    useEffect(() => {
        AOS.init({
            duration: 800,
            offset: 200,
        });
    }, []);

    useEffect(() => {
        AOS.refresh();
    });
    return (
        <MainDiv id='home'>
            <Container maxWidth='lg'>
                <NavBackground>
                    <LockerMenu />
                </NavBackground>
            </Container>
            <ContainerGrid maxWidth='lg'>
                <Grid container>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <DataImage data-aos="fade-up"
                            data-aos-duration="3000" mw599='90%' mw='35%' src={HeaderImage} />
                    </Grid>
                    <Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
                        <Box sx={{ padding: '20px 0px', maxWidth:'400px'}}>
                        <BoxBackground>
                            <Paragraph>
                                000xxX0000XXXXX
                                00000000000X0X000
                            </Paragraph>
                        </BoxBackground>
                        </Box>
                    </Grid>
                </Grid>
            </ContainerGrid>
        </MainDiv>
    )
}
export default Header;
