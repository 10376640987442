import React from "react";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage, SocialBox } from "../../../Styles/style";
import Twitter from '../../../../assets/images/twittericoon.png';
import Telegram from '../../../../assets/images/telegramicon.png';

const DesktopMenu = (props) => {

  return (
    <DesktopMainMenu maxWidth="xl">
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink>)}
      </div>
      <div style={{display: 'flex'}}>
        <MenuLink href="https://twitter.com/Cache_Erc20" p="0px 10px">
          <SocialBox>
          <DataImage mw='16px' src={Twitter} />
          </SocialBox>
        </MenuLink>
        <MenuLink href="https://t.me/Cache_Erc20" p="0px 10px">
          <SocialBox>
          <DataImage mw='17px' src={Telegram} />
          </SocialBox>
        </MenuLink>
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
