import { Box, Container, Grid } from "@mui/material";
import React from "react";
import TokenBg from '../../../../assets/images/tokenback.png';
import styled from "@emotion/styled";
import { DataImage, Heading, Paragraph, SocialBox } from "../../../Styles/style";
import TokenImage from '../../../../assets/images/tokenomicsImage.png';
import TokenBackGrid from '../../../../assets/images/gridback.png';
import { MenuLink } from "../../LockerMenu/styles";
import Twitter from '../../../../assets/images/twittericoon.png';
import Telegram from '../../../../assets/images/telegramicon.png';

const MainDiv = styled.div`
    background-image: url(${TokenBg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    min-height: 100vh;
    margin: auto;
    display: grid;
    place-items: center;
`
const FlexGrid = styled(Grid)`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px 0px;
    @media (max-width: 599px){
        justify-content: center;
    }
`
const GridBox = styled(Box)`
    background-image: url(${TokenBackGrid});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 15px;
`
const FooterGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    @media (max-width: 600px){
        flex-direction: column;
        padding: 5px 0px;
    }
`
const Tokenomics = () => {
    return (
        <div id='tokenomics'>
            <MainDiv>
                <Container maxWidth='lg'>
                    <Grid container sx={{ padding: '30px 0px' }}>
                        <Grid container>
                            <FlexGrid item xs={12} md={6}>
                                <DataImage data-aos="fade-up"
                                    data-aos-duration="3000" mw='60%' src={TokenImage} />
                            </FlexGrid>
                            <Grid item xs={12} md={6}>
                            <Grid item xs={12} sx={{ padding: '20px 0px' }}>
                            <Heading
                                data-aos="fade-up"
                                fs599='60px'
                                cl='black'
                                variant="h1">
                                Tokenomics
                            </Heading>
                        </Grid>
                                <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                                    <Paragraph
                                        data-aos="fade-up"
                                        bd='2px solid black'
                                        fs='18px'
                                        cl='black'
                                        p='0px 20px'
                                    >
                                        Name</Paragraph>
                                    <GridBox data-aos="fade-up">
                                        <Paragraph
                                            data-aos="fade-up"
                                            fs='20px'
                                        >
                                            Cache - Groks 2nd Dog
                                        </Paragraph>
                                    </GridBox>
                                </Grid>
                                <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                                    <Paragraph
                                        data-aos="fade-up"
                                        bd='2px solid black'
                                        fs='18px'
                                        cl='black'
                                        p='0px 20px'
                                    >
                                        Symbol</Paragraph>
                                    <GridBox data-aos="fade-up">
                                        <Paragraph
                                            data-aos="fade-up"
                                            fs='20px'
                                        >
                                            CACHE
                                        </Paragraph>
                                    </GridBox>
                                </Grid>
                                <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                                    <Paragraph
                                        data-aos="fade-up"
                                        bd='2px solid black'
                                        fs='18px'
                                        cl='black'
                                        p='0px 20px'
                                    >
                                        Supply</Paragraph>
                                    <GridBox data-aos="fade-up">
                                        <Paragraph
                                            data-aos="fade-up"
                                            fs='20px'
                                        >
                                            100000000
                                        </Paragraph>
                                    </GridBox>
                                </Grid>
                                <Grid item xs={12} sx={{ padding: '10px 0px' }}>
                                    <Paragraph
                                        data-aos="fade-up"
                                        bd='2px solid black'
                                        fs='18px'
                                        cl='black'
                                        p='0px 20px'
                                    >
                                        Taxes</Paragraph>
                                    <GridBox>
                                        <Paragraph
                                            fs='20px'
                                        >
                                            0/0
                                        </Paragraph>
                                    </GridBox>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MainDiv>
            <Container maxWidth='lg'>
                <Grid container>
                    <FooterGrid item xs={12}>
                        <Paragraph
                            cl='#56EC7F'
                            fs='18px'
                            p='10px 0px'
                        >
                            All Rights Reserved
                        </Paragraph>
                        <div style={{ display: 'flex', padding: '10px 0px' }}>
                            <MenuLink href="https://twitter.com/Cache_Erc20" p="0px 10px">
                                <SocialBox>
                                    <DataImage mw='16px' src={Twitter} />
                                </SocialBox>
                            </MenuLink>
                            <MenuLink href="https://t.me/Cache_Erc20" p="0px 10px">
                                <SocialBox>
                                    <DataImage mw='17px' src={Telegram} />
                                </SocialBox>
                            </MenuLink>
                        </div>
                    </FooterGrid>
                </Grid>
            </Container>
        </div>
    )
}
export default Tokenomics