import styled from "@emotion/styled";
import React from "react";
import ButtonBg from '../../../assets/images/buttonimg.png';
import ButtonBgHover from '../../../assets/images/buttonhover.png';
import { Button } from "@mui/material";

const ButtonStyle = styled(Button)`
    background-image: url(${ButtonBg}); 
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #56EC7F;
    text-transform: none;
    padding: 5px 30px;
    font-size: 16px;
    margin: 10px 10px;
    transition: transform 0.2s ease;
    :hover{
        transform: translateY(5px) !important;
        background-image: url(${ButtonBgHover}); 
    }
`
const CustomeButton = (props) => {
    const { button } = props;
    return(
        <ButtonStyle data-aos="zoom-in">{button}</ButtonStyle>
    )
}
export default CustomeButton;